* {
    box-sizing: border-box;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 800px;
    }
}

.modal-lg {
    max-width: 90%;
}

.modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
}

.modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.fade {
    transition: opacity .15s linear;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.col-md-12 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.main-container {
    padding-top: 10px;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.main {
    position: relative;
}

body {
    margin: 0;
    font-family: roboto condensed, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    color: #1e1e1e;
    text-align: left;
    background-color: var(--primary-bg);
    height: 100%;
}

.modal-open {
    overflow: hidden;
}

html {
    font-family: roboto condensed, sans-serif;
    line-height: 15px;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
    height: 100%;
    color: #1e1e1e;
    font-size: 14px;
    background-color: var(--primary-bg);
}

:root {
    --theme1-bg: #0088cc;
    --theme1-bg90: #0088ccE6;
    --primary-color: #fff;
    --primary-bg: #fff;
}

.modal-content {
    display: flex;
    display: -ms-flexbox;
    position: relative;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: var(--primary-bg);
    background-clip: padding-box;
    border: 0;
    border-radius: .3rem;
    outline: 0;
    padding: 0 1rem;
    box-shadow: 0 8px 10px rgba(0, 0, 0, .5);
}

*,
:after,
:before {
    box-sizing: border-box;
}

.modal-header {
    display: flex;
    display: -ms-flexbox;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 0;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
    background-color: transparent;
    border-radius: 4px;
}

.modal-footer {
    display: flex;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 0;
}

h4 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
    font-size: 1.5rem;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

button {
    border-radius: 0;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    overflow: visible;
    text-transform: none;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

button,
[type="button"] {
    -webkit-appearance: button;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}

.modal-header .close {
    padding: 0;
    margin: -1rem -1rem -1rem auto;
    background-color: var(--theme1-bg);
    opacity: 1;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    margin-top: -2px;
    color: var(--primary-color);
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75;
}

.modal-header .close:hover {
    color: var(--primary-color);
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    margin-bottom: 0 !important;
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn:hover {
    text-decoration: none;
}

table {
    border-collapse: collapse;
}

.table {
    width: 99.99%;
    max-width: 99.99%;
    margin-bottom: 0 !important;
    background-color: transparent;
}

.lay-f {
    background-color: #faa9ba !important;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: rgba(0, 0, 0, .05);
}

.back-f {
    background-color: #72bbef !important;
}

.table td {
    padding: .75rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

.text-secondary {
    color: #6c757d !important;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a.text-secondary:hover {
    color: #545b62 !important;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-AMcYP.style-AMcYP {
    color: green;
}

#style-dOMN1.style-dOMN1 {
    color: red;
}

#style-224CJ.style-224CJ {
    color: red;
}

#style-XIExk.style-XIExk {
    color: red;
}